var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('button',{ref:"openModalAddPayment",staticClass:"d-none",attrs:{"data-toggle":"modal","data-target":"#AddPaymentModal"}}),_c('div',{staticClass:"modal fade",attrs:{"id":"AddPaymentModal","tabindex":"-1","aria-labelledby":"exampleModalLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered modal-dialog-scrollable"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h6',{staticClass:"modal-title",attrs:{"id":"exampleModalLabel"}},[_vm._v("Add Payment")]),_c('button',{ref:"closeModalAddPayment",staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body"},[_c('form',[_c('div',{staticClass:"registry-sign-up-form-content"},[_c('div',{staticClass:"company-info-wrapper"},[_c('div',{staticClass:"container-fluid p-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6 px-0 pr-md-2"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"customer_name"}},[_vm._v("Reference")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.reference.$model),expression:"$v.details.reference.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                            'is-invalid': _vm.$v.details.reference.$error,
                            'is-valid': !_vm.$v.details.reference.$invalid,
                          },attrs:{"type":"text","placeholder":"Reference","autocomplete":"off","id":"reference"},domProps:{"value":(_vm.$v.details.reference.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.reference, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.reference.required)?_c('span',[_vm._v("Reference is required")]):_vm._e()])])]),_c('div',{staticClass:"col-12 col-md-6 px-0"},[_c('div',{staticClass:"form-group"},[_vm._m(0),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.date.$model),expression:"$v.details.date.$model",modifiers:{"trim":true}}],staticClass:"form-control px-2",class:{
                            'is-invalid': _vm.$v.details.date.$error,
                            'is-valid': !_vm.$v.details.date.$invalid,
                          },attrs:{"id":"date","type":"date","placeholder":"Date","autocomplete":"off"},domProps:{"value":(_vm.$v.details.date.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.date, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.date.required)?_c('span',[_vm._v("Date is is required")]):_vm._e()])])]),_c('div',{staticClass:"col-12 col-md-6 px-0 pr-md-2"},[_c('div',{staticClass:"form-group"},[_vm._m(1),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.due_amount.$model),expression:"$v.details.due_amount.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                            'is-invalid': _vm.$v.details.due_amount.$error,
                            'is-valid': !_vm.$v.details.due_amount.$invalid,
                          },attrs:{"type":"number","id":"due_amount","readonly":"","placeholder":"Due Amount","autocomplete":"off"},domProps:{"value":(_vm.$v.details.due_amount.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.due_amount, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.due_amount.required)?_c('span',[_vm._v("Due Amount is required")]):_vm._e()])])]),_c('div',{staticClass:"col-12 col-md-6 px-0"},[_c('div',{staticClass:"form-group"},[_vm._m(2),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.amount.$model),expression:"$v.details.amount.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                            'is-invalid': _vm.$v.details.amount.$error,
                            'is-valid': !_vm.$v.details.amount.$invalid,
                          },attrs:{"type":"number","id":"amount","placeholder":"Amount","autocomplete":"off"},domProps:{"value":(_vm.$v.details.amount.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.amount, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.amount.required)?_c('span',[_vm._v("Amount is required")]):_vm._e()])])]),_c('div',{staticClass:"col-12 px-0"},[_c('div',{staticClass:"form-group"},[_vm._m(3),_c('select',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.payment_method.$model),expression:"$v.details.payment_method.$model",modifiers:{"trim":true}}],staticClass:"form-control pl-2",class:{
                            'is-invalid': _vm.$v.details.payment_method.$error,
                            'is-valid': !_vm.$v.details.payment_method.$invalid,
                          },attrs:{"name":"cash_method","id":"cash_method","required":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.$v.details.payment_method, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"","selected":""}},[_vm._v("Cash Method")]),_c('option',{attrs:{"value":"Cash"}},[_vm._v("Cash")]),_c('option',{attrs:{"value":"Credit Card"}},[_vm._v("Credit Card")]),_c('option',{attrs:{"value":"Bank Transfer"}},[_vm._v("Bank Transfer")]),_c('option',{attrs:{"value":"Cheque"}},[_vm._v("Cheque")]),_c('option',{attrs:{"value":"Other"}},[_vm._v("Other")])])])])])])])]),_c('button',{staticClass:"custom-btn2 p-2",attrs:{"type":"button","disabled":_vm.isLoadingArray[0]},on:{"click":function($event){return _vm.addPayment(0)}}},[(_vm.isLoadingArray[0])?_c('div',{staticClass:"lds-dual-ring"}):_c('span',[_vm._v("Add Payment")])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"contact_person"}},[_vm._v(" Date "),_c('span',{staticClass:"astrick"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"due_amount"}},[_vm._v(" Due Amount "),_c('span',{staticClass:"astrick"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"amount"}},[_vm._v(" Amount "),_c('span',{staticClass:"astrick"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"cash_method"}},[_vm._v(" Cash Method "),_c('span',{staticClass:"astrick"},[_vm._v("*")])])
}]

export { render, staticRenderFns }