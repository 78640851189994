<template>
  <div class="app-page">
    <LeftMenu />
    <div class="main-wrapper">
      <Navbar />
      <div class="registry-wrapper">
        <div class="info-top">
          <h6>
            Edit Payment
          </h6>
          <div>
            <button
              class="custom-btn2"
              v-if="!enableProductStatus"
              @click="enableProduct"
            >
              Add Product
            </button>
            <button
              class="custom-btn4"
              v-if="enableProductStatus"
              @click="enableProduct"
            >
              Disable Product
            </button>
            <router-link class="custom-btn4" to="/payment-payable">
              <span>Back</span>
            </router-link>
          </div>
        </div>

        <editSearchbar :enableProductStatus="enableProductStatus" :oldData="oldData"/>
        <editPaymentPayableForm :enableProductStatus="enableProductStatus" :oldData="oldData" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LeftMenu from "../components/leftmenu/LeftMenu.vue";
import Navbar from "../components/header/Navbar.vue";
import editSearchbar from "../components/search/editSearchbar.vue";
import editPaymentPayableForm from "../components/paymentpayable/editPaymentPayableForm.vue";
import axios from "axios"

export default {
  name: "editPaymentPayable",

  components: {
    Navbar,
    LeftMenu,
    editSearchbar,
    editPaymentPayableForm,
  },

  data: function() {
    return {
      enableProductStatus: false,
      oldData: {}
    };
  },

  computed: {
    ...mapGetters("auth", ["user"]),
  },
  created() {},
  methods: {
    enableProduct() {
      this.enableProductStatus = !this.enableProductStatus;
    },
    fetchPaymentDetail() {
      var formData = new FormData();
      formData.append("purchase_id", this.purchase_id);
      axios
        .post(
          `${process.env.VUE_APP_API_URL}paymentPurchase/details`, formData)
        .then((response) => {
          if (response.data.statusCode == 200) {
            
            this.oldData = response.data.data;
            if(this.oldData.purchaseDetail.length>0)
            {
              this.enableProductStatus=true;
            }
            else
            {
               this.enableProductStatus=false;
            }
            this.showLoader = 0;
          } else {
            this.errorMessage = response.data.message;
            this.showLoader = 0;
          }
        });
    },
    getLinkUrl() {
      var productId = window.location.pathname;
      var segment_array = productId.split("/");
      this.purchase_id = segment_array.pop();
      if (this.purchase_id !== "payment-payable-detail") {
        this.fetchPaymentDetail();
      }else{
        this.$router.push({ name: "PaymentPayable" });
      }
    },

  },

  mounted() {
    this.getLinkUrl();
  },
};
</script>
