<template>
  <div class="top-wrapper">
    <h6>Payment Payable Management</h6>
    <router-link class="custom-btn2" to="/create-payment-payable"
      >Create Payment</router-link
    >
  </div>
</template>

<script>
export default {
  name: "PaymentPayableTop",
};
</script>

<style></style>
